@import '_global';


@import 'uikit';

header{
    background: $white;
    height: 80px;
    display: flex;     
    transition: .5s;
    &.grey{
        background: $dirtyWhite;
        transition: .5s;
    }
    .conteiner{
        align-items: center;
        align-items: center;
        justify-content: center;
        @media screen and (max-width:1900px) {
            justify-content: end;
        }
    }
    .logo{
        background: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5em 2.2rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 110;   
        @media screen and (max-width:1600px) {
            padding: 1.5em 1.5rem;
        }
        @media screen and (max-width:1300px) {
            padding: 1.5em 0.5rem;
        }
        @media screen and (max-width:991px) {
            position: absolute;
        }
    }
     nav{
        display: flex;
        align-items: center;
        .menu{
            display: flex;
            align-items: center;
            padding-right: 1.5rem;
            cursor: pointer;
            @media screen and (max-width:1600px) {
                padding-right: .8rem;
            }
            @media screen and (max-width:1360px) {
                padding-right: 0rem;
            }
            .burger{
                display: block;
                width: 28px;
                height: 28px;
                padding: 3.5px 0px;
                margin-right: 2.5rem;
                position: relative;
                @media screen and (max-width:1600px) {
                    margin-right: 1.5rem;
                }
                @media screen and (max-width:1360px) {
                    margin-right: .5rem;
                }
                span{
                    width: 100%;
                    height: 3px;
                    display: block;
                    background: $black;
                    margin-bottom: 5px;
                    transition: .5s ease;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            .text{
                @include h6;
                font-weight: 700;
                line-height: 18px;
                text-transform: uppercase;
                transition: .5s ease;
                display: block;
                @media screen and (max-width:1360px) {
                    display: none;
                }
                .open{
                    display: block;
                    opacity: 1;
                }
                .bclose{
                    display: none;
                    opacity: 0;
                }
            }
            &.open{
                .burger{
                    span{
                        transition: .5s ease;
                        transform: rotate(45deg);
                        position: absolute;
                        &:nth-child(2){
                            display: none;
                        }
                        &:nth-child(1){
                            top: 12px;
                            transform: rotate(45deg);
                            margin-bottom: 0;
                        }
                        &:nth-child(3){
                            transform: rotate(-45deg);
                            top: 12px;
                        }
                    }
                }
                .text{
                    transition: .5s ease;
                    .open{
                        display: none;
                        opacity: 0;
                    }
                    .bclose{
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }
        ul{
            @include h_list;
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 38px;
            @media screen and (max-width:1200px) {
                margin-right: 0; 
                display: none;               
            }
            li{
                a{
                    color: $black;
                    font-family: Montserrat;
                    font-size: 13.875px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px; /* 129.73% */
                    text-transform: uppercase;
                    padding: 0 1.6rem;
                    transition: .5s ease;
                    @media screen and (max-width:1600px) {
                        padding: 0 .8rem;
                    }
                    @media screen and (max-width:1360px) {
                        padding: 0 .4rem;
                    }
                    &:hover{
                        color: $yellow;
                        transition: .5s ease;
                    }
                    &.active{
                        text-decoration: underline;
                        color: #FED02D;
                        transition: .5s ease;
                    }
                }
            }
        }
     }
    .social{
        display: flex;
        align-items: center;
        @media screen and (max-width:991px){
            justify-content: center;
        }
        ul{
            margin-bottom: 0;
            padding: 0 1rem 1rem 2rem !important;
            @media screen and (max-width:991px){
                padding-left:0 !important;
                padding-top:2rem !important;
            }
            @include h_list;
            li{
                margin-right: 8px;
                &:last-child{
                    margin-right: 0;
                }
                @media screen and (max-width:991px){
                    margin-right: 16px;
                }
                img{
                    width: 30px;
                    @media screen and (max-width:991px){
                        width: 40px;
                    }
                }
            }
        }
    }
    .tel_adress{
        position: relative;
        padding:  0 13px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $white;
        @media screen and (max-width:1600px) {
            margin-right: 4rem;
        }
        @media screen and (max-width:991px) {
            display: none;
        }
        .heat{
            display: flex;
            align-items: center;
            background: $white;
            span{
                @include h6;
                @include bold;
                @include line-height-18;
                margin-left: 12px;
            }
            .arrow{
                margin-left: 15px;
                transition: .5s;
            }
        }
        .body{
            background: $white;
            height: 0;
            transition: .5s ease;
            overflow: hidden;
            visibility: hidden;
            position: absolute;
            z-index: 100;
            top:80px;
            right: 0;
            width: 280px;
            opacity: 0;
            ul{
                list-style: none;
                padding: 8px 16px 16px 30px;
                margin-bottom: 0;
                li{
                    @include h7;
                    color:$lightGray;
                    font-weight: 400;
                    line-height: 19px;
                    text-transform: capitalize;
                    a{
                        @include h7;
                        color:$lightGray;
                        font-weight: 400;
                        line-height: 19px;
                        text-transform: capitalize;
                    }
                    
                    span{
                        @include h7;
                        color:$black;
                        margin-right: 5px;
                        font-weight: 400;
                        line-height: 19px;
                        text-transform: capitalize;
                    }
                }
            }
            .btn_main{
                border-radius: 0;
                font-size: 13.625px;
                font-style: normal;
                font-weight: 700;
                line-height: 19.2px;
                width: 100%;
                height: 60px;
                svg{
                    position: relative;
                    left: -10px;
                    path{
                        transition: .5s;
                    }
                }
                &:hover{
                    svg{
                        path{
                            fill: $white;
                            transition: .5s;
                        }
                    }
                }
            }

        }
        &:hover{
            .arrow{
                transition: .5s ease;
                transform: rotate(180deg);
            }
            .body{
                visibility: visible;
                height: auto;
                transition: .5s ease;
                opacity: 1;
            }
        }
    }
    .mega_menu{
        position: fixed;
        top: 80px;
        width: 100%;
        height: calc(100vh - 80px);
        background: rgb(232 233 235 / 95%);
        transform: translateX(-1000%);
        transition: 1s ease;
        display: flex;
        z-index: 110;
        @media screen and (max-width:991px) {
            height: max-content;
            z-index: 111;
            position: absolute;
        }
        .conteiner{
            align-items: start;
            @include jcsb;
            padding: 80px 0;
            @media screen and (max-width:1480px) {
                padding: 30px 15px;
            }
            @media screen and (max-width:991px) {
                padding: 0;
                flex-direction: column;
                justify-content: flex-start;
            }
        }
        &.open{
            transform: translateX(0);
            transition: 1s ease;
        }
        .mob_menu{
            @include h_list;
            flex-direction: column;
            width: 100%;
            padding: 2rem 1rem 0;
            li{
                position: relative;
                a{
                    @include h6;
                    font-weight: 700;
                    color: $darkGray;
                    text-transform: uppercase;
                    padding: 16px 0;
                    display: flex;
                    position: relative;
                    &::after{
                        content: '';
                        width: 0;
                        height: 3px;
                        background: $darkGray;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: width .5s ease;
                    }
                }
                span{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 30px;
                    height: 30px;
                    transition: .5s ease;
                    transform: rotate(0deg);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                ul{
                    height: 0;
                    overflow: hidden;
                }
                &.active{
                    a{
                        &::after{
                            width: 100%; 
                            transition: width .5s ease;
                        }
                    }
                    span{
                        transition: .5s ease;
                        transform: rotate(180deg);
                    }
                    ul{
                        display: flex;
                        flex-direction: column;
                        list-style: none;
                        height: auto;
                        transition: height .5s ease;
                        overflow: inherit;
                        margin-top: 4px;
                        li{
                            a{
                                @include h5;
                                padding: 8px 0;
                                text-transform: none;
                                &::after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
           
        }
        .mob_info{
            padding: 0 1rem 1rem;
            width: 100%;
            ul{
                @include h_list;
                justify-content: space-between;
                margin-bottom: 0;
                li{
                    @include h7;
                    @include regular;
                    color: $darkGray;
                    text-transform: none;
                    span{
                        color: $black;
                    }
                    a{
                        @include h7;
                        @include regular;
                        color: $darkGray;
                    }
                }
            }
            .btn_main{
                width: 100%;
                @include h7;
                border-radius: 0;
                height: 60px;
                svg{
                    margin-right: 10px;
                }
            }
        }
        .left{
            display: flex;
            width: 370px;
            flex: 0 0 370px;
            margin-right: 124px;
            @media screen and (max-width:1300px) {
                width: 250px;
                flex: 0 0 250px;
                 margin-right: 4rem;
            }
            ul{
                @include h_list;
                flex-direction: column;
                width: 100%;
                li{
                    width: 100%;
                    a{
                        @include h6;
                        @include bold;
                        color:$darkGray;
                        padding: 15px 0;
                        display: flex;
                        position: relative;
                        width: 100%;
                        text-transform: uppercase;
                        &::after{
                            content: '';
                            width: 0;
                            height: 3px;
                            background: $darkGray;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transition: width .5s ease;
                        }
                        &:hover{
                            color: $black;
                            text-decoration: none;
                            &::after{
                                width: 100%;
                                transition: width .5s ease;
                            }
                        }
                        &.active{
                            color: $black;
                            text-decoration: none;
                            &::after{
                                width: 100%;
                                transition: width .5s ease;
                            }
                        }
                        
                    }
                    &:first-child a{
                        padding-top: 0;
                    }
                }
            }
        }
        .right{
            display: flex;
            width: 100%;
            .menu_item{
                display: none;
                width: 100%;
                position: relative;
                top: -10px;
                &.active{
                    display: block;
                }
                .title{
                    @include h2;
                    margin-bottom: 38px;
                }
                ul{
                    @include h_list;
                    flex-direction: column;
                    a{
                        @include h6;
                        @include bold;
                        color: $black;
                        margin: 11px 0;
                        display: block;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    li{
                        &:first-child{
                            a{
                                margin-top: 0;
                            }
                        }
                    }
                }
                &.catalog{
                    .body{
                        height: 70vh;
                        width: 100%;
                        display: flex;
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 8px;              
                        }
                        &::-webkit-scrollbar-track {
                            background: $black;       
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $red;    
                            border-radius: 10px;      
                            border: 2px solid $black; 
                        }
                    }
                    .items{
                        width: 100%;
                        column-gap: 40px;
                        display: grid;
                        grid-template-columns: repeat(auto-fill, 30%);
                        justify-content: center;
                        row-gap: 35px;
                        height: max-content;
                        @media screen and (max-width:1480px) {
                            justify-content: flex-start;
                        }
                    }
                    .item{
                        &:hover{
                            text-decoration: none;
                        }
                        img{
                            width: 100%
                        }
                        max-height: 100%;
                    }

                    .name{
                        @include h6;
                        @include bold;
                        color:$black;
                        margin-top: 10px;
                        display: block;
                    }
                }
            }
        }
    }
}

.section_footer_form{
    padding: 5rem 0 7.5rem;
    @media screen and (max-width: 991px) {
        padding-top: 0px;
        padding-bottom: 3.5rem;
    }
    .conteiner{
        padding: 0;
    }
    .footer_form{
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
        & > div{
            display: flex;
            overflow: hidden;
            position: relative;
            width: calc(50% - 1rem);
            justify-content: center;
            @media screen and (max-width: 991px) {
                width: 100%;
                order:2;
                padding: 0 1rem 2.5rem;
                background: $yellow;
            }
            img{
                height: 100%;
                position: absolute;
                @media screen and (max-width: 991px) {
                    position: static;
                    width: 100%;
                }
            }
            picture{
                width: 100%;
                height: 100%;
                position: absolute;
                @media screen and (max-width: 991px) {
                    position: static;
                    width: 100%;
                }
            }
        }
        &_form{
            background: $yellow;
            margin-left: 26px;
            display: flex;
            width: calc(50% - 1rem);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 4.5rem 4.6rem;
            @media screen and (max-width: 991px) {
                width: 100%;
                margin-left: 0;
                order: 1;
                padding: 4.5rem 1rem;
                padding-bottom: 2.5rem;
                margin-bottom: 0;
            }
        }
        &_title{
            @include h2;
            font-weight: 500;
            text-align: center;
            margin-bottom: 3.5rem;
        }
        &_body{
            width: 100%;

        }
        button{
            font-size: 28px;
            width: 100%;
        }
    }
}

footer{
    background: $black;
    height: 110px;
    display: flex;
    transition: margin .5s ease;
    @media screen and (max-width:991px) {
        margin-bottom: 80px;
    }
    .user-panel{
        position: fixed;
        display: flex;
        top:0;
        right: 0;        
        z-index: 110;
        transition: top .5s ease;
        @media screen and (max-width:991px) {
            width: 100%;
            height: 80px;
            z-index: 110;
            top: auto;
            bottom: 0px;      
            flex-direction: column;     
        }
        ul{
            width: 80px;
            background: $black;
            list-style: none;
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            @media screen and (max-width:991px) {
                column-gap: 0px;
                width: 100%;
                height: 80px;
                display: grid;
                grid-template-columns: repeat(auto-fill, 20%);
                justify-content: start;
                row-gap: 35px;
            }
            li{
                @media screen and (max-width:991px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.cart{
                        order: 3;
                    }
                    &.search{
                        order: 1;
                    }
                    &.favorites{
                        order: 2;
                    }
                    &.profile{
                        order: 4;
                    }
                    &.langs{
                        order: 5;
                    }
                }
                & > a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 28px;
                    transition: .5s ease;
                    @media screen and (max-width:991px) {
                        margin-bottom: 0;
                        height: 100%;
                        width: 100%;
                        padding: 0;
                    }
                    svg{
                        fill: $white;
                    }
                    &:hover, &.active{
                        background: $yellow;
                        transition: .5s ease;
                    }
                }
                &.cart{
                    a{
                        position: relative;
                        padding: 30px 27px;
                        @media screen and (max-width:991px) {
                            padding: 0;
                        }
                        svg{
                            fill: $yellow;
                            @media screen and (max-width:991px) {
                                fill: $white;
                            }
                        }
                        &:hover, &.active{
                            svg{
                                fill: $white;
                            }
                        }
                        &::after{
                            content: attr(data-count);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            @include h7;
                            font-weight: bold;
                            background: $white;
                            color: $black;
                            border-radius: 60px;
                            position: absolute;
                            right: 20px;
                            top: 20px;
                            opacity: 1;
                            transition: .5s;
                            @media screen and (max-width:991px) {
                                right: 35%;
                                top: 25%;
                            }
                        }
                        &[data-count='0']::after{
                            opacity: 0;
                            transition: .5s;
                        }
                    }
                }
                &.favorites{
                    a{
                        position: relative;
                        &::after{
                            content: attr(data-num);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            @include h7;
                            font-weight: bold;
                            background: $white;
                            color: $black;
                            border-radius: 60px;
                            position: absolute;
                            right: 15px;
                            top: 5px;
                            opacity: 1;
                            transition: .5s;
                            @media screen and (max-width:991px) {
                                right: 35%;
                                top: 25%;
                            }
                        }
                        &[data-num='0']::after{
                            opacity: 0;
                            transition: .5s;
                        }
                    }
                }
                &.langs{
                    display: flex;
                    justify-content: center;
                    .header_langs{
                        padding: 16px 0;
                        position: relative;
                        @media screen and (max-width:991px) {
                            margin-bottom: 0;
                            height: 80px;
                            padding: 0;
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                        }
                        &::before{
                            content: '';
                            display: block;
                            width: 32px;
                            height: 1px;
                            background: $yellow;
                            position: absolute;
                            top: 0;
                            left: 12px;
                            @media screen and (max-width:991px) {
                                left: 0;
                                width: 1px;
                                height: 32px;
                                top: 50%;
                                transform: translateY(-1rem);
                            }
                        }
                        a{
                            @include h7;
                            color: $white;
                            font-size: 11px;
                            position: relative;
                            padding: 0 5px;
                            text-decoration: none;
                            transition: .5s ease;
                            &.active{
                                color:$yellow;
                                transition: .5s ease;
                            }
                            &:hover{
                                color:$yellow;
                                transition: .5s ease;
                            }
                            &::after{
                                content: '|';
                                display: block;
                                position: absolute;
                                right: -5px;
                                top: 0;
                                @media screen and (max-width:991px) {
                                    right: -2px;
                                    top: -1px;
                                }
                            }
                            &:last-child::after{
                                content: '';
                            }
                        }
                        
                    }
                }
                
            }
        }
    }
    .user-panel, .user-panel-mob{
        .body{
            position: relative;
            overflow: hidden;
            display: flex;
            @media screen and (max-width:991px) {
                flex-direction: column;
            }
            .close{
                position: absolute;
                top: 10px;
                right: 10px;
                opacity: 1;
                z-index: 0;
            }
            & > div{
                width: 0;
                height: 100dvh;
                transition: .5s ease;
                opacity: 0;
                @media screen and (max-width:991px) {
                    width: 100%;
                    display: none
                }
                &.active{
                    width: 29vw;
                    transition: .5s ease;
                    opacity: 1;
                    @media screen and (max-width:991px) {
                        width: 100vw;
                        display: block
                    }
                    .close{
                        z-index: 50;
                    }
                }
            }
            .search{
                background:$dirtyWhite;
                &.active{
                    padding: 4.1666666667vw 3.125vw;
                    @media screen and (max-width:991px) {
                        padding: 14vw 10vw;                        
                    }
                }
                form{
                    display: flex;
                    
                    input{
                        transition: border-color .3s ease, box-shadow .3s ease, color .3s ease, opacity .2s;
                        border: none;
                        border-bottom: 3px solid;
                        border-color: $lightGray;
                        width: 100%;
                        height: 60px;
                        background: $dirtyWhite;
                        font-size: .8333333333vw;
                        font-family: Montserrat, sans-serif;
                        @media screen and (max-width:991px) {
                            font-size: 16px;
                        }
                        &:hover,&:active{
                            border-color: $black;
                        }
                        &:focus{
                            outline: none;
                            border-color: $black;
                        }
                    }
                    button{
                        border-radius: 0;
                        border: 0;
                        width: 60px;
                        height: 60px;
                        svg{
                            fill: $white;
                        }
                        @media(hover:hover){
                            &:hover{
                                svg{
                                    fill: $white;
                                }
                            }
                        }
                        @media(hover:none){
                            &:active{
                                svg{
                                    fill: $white;
                                }
                            }
                        }  
                    }
                }
                .search_popular{
                    margin-top: 2rem;
                    .title{
                        @include h4;
                        color: $black;

                    }
                    .list{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        margin-top: 1rem;
                        a{
                            padding: 0 0.9375vw;
                            height: 2.0833333333vw;
                            @include p;
                            font-size: 1rem;
                            color:$darkGray;
                            border: 1px solid $lightGray;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom:.625rem;
                            margin-right:.625rem;
                            @media screen and (max-width:991px) {
                                height: auto;
                                padding: 0 5px;
                            }
                            @media(hover:hover){
                                &:hover{
                                    background: $yellow;
                                    color:$black;
                                    text-decoration: none;
                                    transition: .5s ease;
                                }
                            }
                            @media(hover:none){
                                &:active{
                                    background: $black;
                                    color:$black;
                                    text-decoration: none;
                                    transition: .5s ease;
                                }
                            }  
                        }
                    }
                }
            }
            .cart{
                background: $white;
                flex-direction: column;
                &.active{
                    width: 40vw;
                    display: flex;
                    @media screen and (max-width:1700px) {
                        width: 45vw;
                    }
                    @media screen and (max-width:1500px) {
                        width: 50vw;
                    }
                    @media screen and (max-width:1360px) {
                        width: 55vw;
                    }
                    @media screen and (max-width:1199px) {
                        width: 65vw;
                    }
                    @media screen and (max-width:991px) {
                        width: 100vw;
                    }
                }
                .header{
                    padding: 3.125vw 2.0833333333vw 1.25vw 3.125vw;
                    border-bottom: 1px solid $lightGray;
                    @include h3;
                    @media screen and (max-width:991px) {
                        padding: 2.5rem 1.25rem 1.5rem;
                    }
                }
                .content{
                    display: flex;
                    overflow: hidden;
                    flex: 1 1 auto;
                    background-color: #fff;
                    padding: 1.25vw 2.0833333333vw 0.78125vw 3.125vw;
                    .products{
                        width: 100%;
                        max-height: 100%;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .product{
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid #e8e9eb;
                            padding: 1.25vw 0;
                            align-items: center;
                            padding-right: 15px;
                            @media screen and (max-width:991px) {
                                flex-wrap: wrap;
                            }
                            &:last-child{
                                border:0;
                            }
                            .img_name{
                                display: flex;
                                width: 18rem;
                                @media screen and (max-width:991px) {
                                    width: 100vw;
                                }
                                .img{
                                    width: 7rem;
                                    img{
                                        width: 100%;
                                    }
                                }
                                .name{
                                    width: 10rem;
                                    @include h6;
                                    color: $black;
                                    @media screen and (max-width:991px) {
                                        width: 100%;
                                    }
                                    .properties{
                                        font-size: .7rem;
                                    }
                                }
                            }
                            .count{
                                display: flex;
                                height: 40px;
                                align-items: center;
                                box-shadow: 0 0.1041666667vw 0.2083333333vw rgba(0,0,0,.15);
                                .count_btn{
                                    display: inline-flex;
                                    justify-content: center;
                                    flex-shrink: 0;
                                    font-size: 28px;
                                    line-height: .8;
                                    color: #828282;
                                    -webkit-user-select: none;
                                    user-select: none;
                                    transition: color .3s;
                                    cursor: pointer;
                                    padding: 0 5px;
                                }
                                .quantity{
                                    input{
                                        width: 1.5rem;
                                        font-family: Montserrat, sans-serif;
                                        font-size: 14px;
                                        color: #000000;
                                        text-align: center;
                                        border: none;
                                        width: 4rem;
                                        &[type='number']{
                                            -moz-appearance: textfield;
                                            -ms-appearance: textfield;
                                            -webkit-appearance: textfield;
                                        }
                                        &::-webkit-outer-spin-button{
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                        &::-webkit-inner-spin-button{
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                        
                                    }
                                }
                            }                        
                            .price{
                                display: flex;
                                flex-direction: column;
                                font-family: Montserrat, sans-serif;
                                font-weight: 400;
                                font-size: .875rem;
                                line-height: 1.2142857143em;
                                color: #8b8178;
                                width: 10.5rem;
                                padding-left: 17px;
                                b{
                                    font-size: 14px;
                                }
                                strong{
                                    font-size: 16px;
                                    color: $black;
                                }
                            }
                            .delete{
                                align-self: flex-start;
                                grid-row: 1 / 1;
                                grid-column: 6 / 6;
                                padding: 5px;
                                cursor: pointer;
                                padding: 0.3125rem;
                                border: 1px solid rgba(139, 129, 120, .5);
                                transition: border-color .3s;
                                margin: auto;
                                @media screen and (max-width:991px) {
                                    justify-self: end;
                                    grid-area: remove-button;
                                    margin: 0;
                                }
                                svg {
                                    width: 12px;
                                    height: 12px;
                                    fill: #8b8178;
                                    display: block;
                                    transition: fill .3s;
                                }
                                &:hover{
                                    border-color: $black;
                                    svg {
                                        fill:$black;
                                    }
                                }
                            }
                        }
                    }
                    
                }
                .footer{
                    flex: 0 0 auto;
                    background-color: #e8e9eb;
                    padding: 1.3020833333vw 3.125vw 3.125vw;
                    @media screen and (max-width:991px) {
                        padding: 1.875rem 1.25rem;
                    }
                    .button{
                        display: flex;
                        justify-content: space-between;
                        padding-top: 1.25rem;
                        a{
                            cursor: pointer;
                        }
                        
                    }
                    .cart_info{
                        text-align: right;
                        span{
                            font-family: Montserrat, sans-serif;
                            font-size: 14px;
                            color: $black;
                            strong{
                                font-weight: 700;
                            }
                            &.summa{
                                font-size: 16px;
                            }
                        }
                    }
                    .btn_main{
                        font-size: 20px;
                        border-radius: 0;
                        height: 60px;
                        @media screen and (max-width:991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .user-panel-mob{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 110;
        transition: .5s ease;
        transform: translateX(-200%);
        &.active{
            transition: .5s ease;
            transform: translateX(0);
        }
    }


    .footer_{
        &_innit{
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

        }
        &_item{
            width: calc(100% / 3);
            @media (max-width: 991px) {
                width: calc(100% / 2);
            }
            &:last-child{
                @media (max-width: 991px) {
                    display: none;
                }
            }
            &.center{
                display: flex;
                justify-content: center;
                img{
                    @media (max-width: 991px) {
                        width: 135px;
                    }
                }
            }
        }
    }
    .social{
        a{
            margin-right: 16px;
            svg{
                fill: $white;
            }
            &:hover{
                text-decoration:none;
                svg{
                    fill:$yellow;
                }
            }
            &:last-child{
                @media (max-width: 991px) {
                    margin-right: 0px;
                }
            }
        }
    }
}
.to-top {
    &{
        position: fixed;
        right: 30px;
        bottom: 40px;
        transition: all .2s linear;
        opacity: 0;
        width: 82px;
        height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $yellow;
        border-radius: 50%;
        transform: rotate(180deg);    
        z-index: 999;
        cursor: pointer;
    }
    @media screen and (max-width:991px) {
        bottom: 150px;
        width: 42px;
        height: 42px;
    }
}