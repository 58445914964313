*{
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body{
    font-family: MontserratRegular, sans-serif;
    margin: 0;
    &:after {
        opacity: 0;
    }
    &:after {
        content: "";
        transition: opacity 0.3s;
    }
    &.shadow{
        &::after{
            content: "";
            transition: opacity 0.3s;
            display: -ms-flexbox;
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.8);
            z-index: 11;
            opacity: 1;
        }
    }
}
.h1,h1{
    font-family: MontserratBold, sans-serif;
    @media screen and (max-width: 380px) {
        font-size: 24px;
        &.main-title{
            font-size: 24px;
        }
    }
}
h2{
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #000;
    font-family: MontserratBold, sans-serif;
    font-size: 24px;
    hyphens: auto;
    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}
h3{
    font-weight: 700;
    text-transform: uppercase; 
    color: #000;
    font-family: MontserratBold, sans-serif;
    font-size: 20px;
    hyphens: auto;
}
h4{
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    font-family: MontserratBold, sans-serif;
    font-size: 16px;
    hyphens: auto;
}
p{
    margin-top: 10px;
    margin-bottom: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: MontserratRegular, sans-serif;
    letter-spacing: 0;
    hyphens: auto;
    @media screen and (max-width: 380px) {
        font-size: 12px;
    }
}
a{
    letter-spacing: 0;
    hyphens: auto;
}
.help-message{
    color: #000;
    padding: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999999;
    background: #fff;
    max-width: 320px;
    max-height: 500px;
    text-align: center;
    transition: 0.3s;
    border: 1px solid #dcdcdc;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    .closeMessage {
        width: 10px;
        height: 10px;
        top: 10px;
        right: 10px;
        position: absolute;
        &:hover {
            cursor: pointer;
        }
    }
}
.btn{
    box-sizing: border-box;
    transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out;
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    color: #000;
    border: 1px solid transparent;
    border-radius: 0;
    background-color: #fff;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    &:focus{
        text-decoration: none;
        outline: 0;
    }
    &:hover{
        text-decoration: none;
    }
    &--default{
        text-decoration: none;
        font-size: 14px;
        padding: 9px 16px;
        text-transform: uppercase;
        font-weight: 700;
        border: 1px solid #000;
        border-radius: 18px;
        &:hover {
            color: #fff;
            background-color: #000;
        }
    }
    &--dark{
        font-size: 14px;
        padding: 9px 16px;
        text-transform: uppercase;
        color: #fff;
        background-color: #000;
        font-weight: 700;
        border: 1px solid #000;
        border-radius: 18px;
        &:hover {
            color: #000;
            background-color: #fff;
        }
    }
    &-right {
        text-align: right;
    }
    &-block {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        a{
            &:first-child{
                margin-right: 20px;
            }
        }
    }
    
}
.disabled{
    opacity: 0.3;
}

.checkbox{
    display: block;
    margin-bottom: 6px;
    input {
        display: none;
        &:checked + span:after {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 16px;
            height: 16px;
            content: "";
            background-color: #000;
        }
    }
    span{
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
        position: relative;
        display: inline-block;
        padding-left: 30px;
        cursor: pointer;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            content: "";
            border: 1px solid #000;
        }
    }
}
.input-text {
    height: 50px;
    padding: 0 15px;
    font-size: 14px;
    display: block;
    width: 100%;
    border: 1px solid #a8a8a8;
    background-color: #fff;
}
.textarea{
    font-size: 14px;
    display: block;
    width: 100%;
    border: 1px solid #a8a8a8;
    background-color: #fff;
    min-height: 100px;
    padding: 15px;
    resize: none;
}

.pagination{
    display: block;
    width: 100%;
    margin: 15px 0;
    text-align: center;
    &__controll{
        vertical-align: middle;
        line-height: 40px;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 8px;
        text-align: center;
        text-decoration: none;
        color: #000;
        border-radius: 50%;
        &:hover{
            text-decoration: none;
            color: red;
        }
    }
    &__first{
        margin: 0 21px;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
    }
    &__last{
        margin: 0 21px;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
    }
    &__page{
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        margin: 0 11px;
        color: #000;
        font-size: 18px;
        @media screen and (max-width: 480px) {
            font-size: 14px;
            margin: 0 8px;
        }
        &:hover {
            color: red;
            text-decoration: none;
        }
        &.active {
            color: red;
        }
    }
    &__more{
        vertical-align: middle;
        display: inline-block;
        margin: 0 12px;
        cursor: default;
    }
}
.error_container {
	color: #b72f2f;
}
.success_container {
	color: green;
}
.errortext {
	color: #b72f2f;
}
.preloader_container {
	display: none;
    &.d-show{
        display: block;
    }
}
.preload__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 10000;
    .sk-chasing-dots {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
        width: 4em;
        height: 4em;
        text-align: center;
        -webkit-animation: sk-chasing-dots-rotate 2s infinite linear;
        animation: sk-chasing-dots-rotate 2s infinite linear;
        .sk-child {
            width: 2em;
            height: 2em;
            display: inline-block;
            position: absolute;
            top: 0;
            background-color: #f00;
            border-radius: 100%;
            -webkit-animation: sk-chasing-dots-bounce 2s infinite ease-in-out;
            animation: sk-chasing-dots-bounce 2s infinite ease-in-out;
        }
        .sk-dot-2 {
            top: auto;
            bottom: 0;
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }
    }
}
.form-group{
    &._error{
        border-left: 2px solid red;
	    padding-left: 10px;
    }
}
label{
    &._error{
        border-left: 2px solid red;
	    padding-left: 10px;
    }
}

.content__heading {
    font-size: 48px;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0;
    text-transform: uppercase;
    color: #000;
    @media screen and (max-width: 991px) {
        font-size: 36px;
    }
    @media screen and (max-width: 767px) {
        font-size: 28px;
    }
    @media screen and (max-width: 480px) {
        text-align: center;
    }
}

@keyframes sk-chasing-dots-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes sk-chasing-dots-bounce {
	0%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.chosen-container * {
    box-sizing: border-box;
}

section.company-discription p {
    line-height: 20px;
}

.catalog__panel-value a,
.chosen-container,
.item__img {
    vertical-align: middle;
}
.contact-item{
    display: -ms-flexbox;
    display: flex;
    &__icon {
        display: -ms-flexbox;
        display: flex;
        margin-right: 20px;
        width: 50px;
        height: 50px;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
    }
    &__detail{
        b{
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            display: block;
            color: #171d25;
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        a{
            color: #171d25;
            font-size: 14px;
        }
    }
}
.radio__text{
    &{
        position: relative;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        font-family: Semi-bold, sans-serif;
        font-weight: 700;
        span{
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            font-family: Semi-bold, sans-serif;
            font-weight: 700;
            &:before{
                content: "";
                display: inline-block;
                width: 18px;
                height: 18px;
                border: 1px solid #cdd1da;
                border-radius: 50%;
                background: #fff;
                margin-right: 10px;
            }
            &:after {
                content: "";
                position: absolute;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #000;
                left: 4px;
                opacity: 0;
                transition: 0.2s;
            }
        }
    }
}
.radio{
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:checked{
            & + .radio__text{
                span{
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
