@import 'mixin';

$black: #000000;
$darkGray: #48494F; 
$yellow: #FED02D; 
$white: #FFFFFF; 
$red: #EB2629;
$lightGray: #B3B3B3;
$lightGreen: #27E394; 
$dirtyWhite: #E8E9EB;

@font-face {
    font-family: 'icomoon';
    src:  url('/fonts/icomoon.eot?4pr3bg');
    src:  url('/fonts/icomoon.eot?4pr3bg#iefix') format('embedded-opentype'),
      url('/fonts/icomoon.ttf?4pr3bg') format('truetype'),
      url('/fonts/icomoon.woff?4pr3bg') format('woff'),
      url('/fonts/icomoon.svg?4pr3bg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: Montserrat;
    font-weight: 400;
    src: url(/fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
    font-family: Montserrat;
    font-weight: 700;
    src: url(/fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
    font-family: Montserrat;
    font-weight: 500;
    src: url(/fonts/Montserrat-Medium.ttf) format("truetype");
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-shopping-cart:before {
    content: "\e900";
}
.icon-chevron-down:before {
    content: "\e901";
}
.icon-chevron-left:before {
    content: "\e902";
}
.icon-chevron-right:before {
    content: "\e903";
}
.icon-chevron-up:before {
    content: "\e904";
}
.icon-mail:before {
    content: "\e905";
}
.icon-map-pin:before {
    content: "\e906";
}
.icon-menu:before {
    content: "\e907";
}
.icon-phone-call:before {
    content: "\e908";
}
.icon-search:before {
    content: "\e909";
}
.icon-youtube:before {
    content: "\e90a";
}
.icon-viber:before {
    content: "\e90b";
}
.icon-arrow-left:before {
    content: "\e90c";
}
.icon-arrow-right:before {
    content: "\e90d";
}
.icon-close:before {
    content: "\e90e";
}
.icon-share2:before {
    content: "\ea82";
}
.icon-facebook:before {
    content: "\ea90";
}
.icon-instagram:before {
    content: "\ea92";
}
.icon-whatsapp:before {
    content: "\ea93";
}
.icon-telegram:before {
    content: "\ea95";
}
.icon-twitter:before {
    content: "\ea96";
}
.icon-skype:before {
    content: "\eac5";
}
.icon-pinterest2:before {
    content: "\ead2";
}
::-webkit-scrollbar {
    width: 8px;              
}
::-webkit-scrollbar-track {
    background: $black;       
}
::-webkit-scrollbar-thumb {
    background-color: $red;    
    border-radius: 10px;      
    border: 2px solid $black; 
}
body{
    &.fixed{
        overflow: hidden;
    }
    &::-webkit-scrollbar {
        width: 8px;              
    }
    &::-webkit-scrollbar-track {
        background: $black;       
    }
    &::-webkit-scrollbar-thumb {
        background-color: $red;    
        border-radius: 10px;      
        border: 2px solid $black; 
    }
    #backgraund_active{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgb(0 0 0 / 80%);
        z-index: 100;
    }
}
.conteiner, .container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0 15px;
    max-width: 1480px;
    @media screen and (max-width:1600px) {
        &{
            max-width: 1200px;
        }
    }
}
main{
    .conteiner, .container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        padding: 0 15px;
        max-width: 1480px;
       
        @media screen and (max-width:1600px) {
            &{
                max-width: 1200px;
            }
        } flex-direction: column;
    }
}
a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

.btn_main{
    display: flex;
    width: 309px;
    height: 81px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transition: .5s ease;
    background-color: $yellow;
    color: $black;
    @include h3;
    svg{
        transition: .5s ease;
        fill: #000000;
    }
    @media(hover:hover){
        &:hover{
            background: $black;
            color:$white;
            text-decoration: none;
            transition: .5s ease;
            svg{
                transition: .5s ease;
                fill: #FFFFFF;
            }
        }
    }
    @media(hover:none){
        &:active{
            background: $black;
            color:$white;
            text-decoration: none;
            transition: .5s ease;
            svg{
                transition: .5s ease;
                fill: #FFFFFF;
            }
        }
    }    
    &:focus{
        outline: none;
        text-decoration: none;
    }
    &.disabled{
        pointer-events: none;
        cursor: default;
        background: $lightGray;
        color: $white;
    }
    &.black{
        color: $white;
        background: $black;
        border-color: $black;
        border: 1px solid $black;
        @media(hover:hover){
            &:hover{
                background: $white;
                color:$black;
                text-decoration: none;
                transition: .5s ease;
            }
        }
        @media(hover:none){
            &:active{
                background: $white;
                color:$black;
                text-decoration: none;
                transition: .5s ease;
            }
        }    
    }
    &.white{
        color: $black;
        background: $white;
        border-color: $black;
        border: none;
        @media(hover:hover){
            &:hover{
                background: $black;
                color:$white;
                text-decoration: none;
                transition: .5s ease;
            }
        }
        @media(hover:none){
            &:active{
                background: $black;
                color:$white;
                text-decoration: none;
                transition: .5s ease;
            }
        }    
    }
}
.input_label, label{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    position: relative;
    input{
        width: 100%;
        @include p;
        display: flex;
        height: 60px;
        border-radius: 30px;
        border: 0;
        padding-left: 80px;
        padding-right: 56px;
        @media screen and (max-width:991px) {
            padding-left: 50px;
            padding-right: 20px;            
        }
        &::placeholder{
            @include p;
            color:$lightGray;
        }
        &:focus{
            outline: none;
            text-decoration: none;
        }
        &:disabled{
            background: $lightGray;
            color: $white;
            &::placeholder{
                color: $white;
            }
        }
        &[type=checkbox], &input[type=radio]{
            width: auto;
            height: auto;
        }
        &[type=checkbox]{
            display: none;
            &:checked{
                & + span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &::before{
                        content: '';
                        display: block;
                        width: 14px;
                        height: 8px;
                        border-left: 2px solid $black;
                        border-bottom: 2px solid $black;
                        transform: rotate(-45deg);
                        margin-top: -4px;
                        transition: width .5s ease;
                    }
                }
            }
        }
    }
    .checkbox{
        border: 2px solid #000000;
        border-radius: 4px;
        width: 24px;
        min-width: 24px;
        height: 24px;
        display: flex;
        margin-right: 1rem;
        &::before{
            content: '';
            width: 0;
            height: 0;
            transform: rotate(-45deg);
            transition: width .5s ease;
        }
    }
    textarea{
        width: 100%;
        @include p;
        display: flex;
        border-radius: 30px;
        border: 0;
        height: 60px;
        padding-left: 80px;
        padding-right: 56px;
        border: none;
        @media screen and (max-width:991px) {
            padding-left: 50px;
            padding-right: 20px;            
        }
        &::placeholder{
            @include p;
            color:$lightGray;
        }
        &:focus{
            outline: none;
            text-decoration: none;
        }
    }
    &.error{
        input{
            color: $red;
            &::placeholder{
                color:$red;
            }
        }
        textarea{
            color: $red;
            &::placeholder{
                color:$red;
            }
        }
        span{
            color: $red;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: absolute;
            bottom: -1rem;

        }
    }
    
}
.pagination-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    .customNav{
        @include h4;
        display: flex;
        justify-content: center;
        align-items: center;
        .customBtn{
            width: 32px;
            height: 32px;
            
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .num-block{
            margin: 0 1rem;
        }
        .customPrev{
            svg{
                transform: rotate(0deg);
            }
        }
        .customNext{
            svg{
                transform: rotate(180deg);
            }
        }
    }
    .btn_main{
        margin-top: 2rem;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        svg{
            display: none;
        }
    }
}
.breadcrumb{
    display: flex;
    background: 0 0;
    margin: 0;
    padding: 2rem 0 2.5rem;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width:991px) {
        padding: 1rem 0 .5rem;
    }
    &__element{
        @include h6;
        color: $darkGray;
        font-weight: 500;
        a{
            @include h6;
            color: $darkGray;
            text-decoration: none;
            font-weight: 500;
        }
        i{
            font-style: normal;
            margin: 0 12px;
        }       
    }
    &__active{
        color: $black;
    }
}
.drop{
    position: relative;
    &_button{
        @include h5;
        color: $blue;
    }
    &_body{
        position: absolute;
        top: 0;
        left: -50%;
        border: 1px solid rgba(0, 0, 0, .15);
        transform: translate3d(55px, 32px, 0px);
        border-radius: .25rem;
        padding: 1rem;
        margin: .125rem 0 0;
        width: 320px;
        background:#FFFFFF;
        z-index: -1;
        opacity: 0;
        height: 0;
        transition: height .5s ease;
        overflow: hidden;
        .header{
            @include h5;
            text-align: center
        }
        p{
            @include h7;
            @include regular;
            text-transform:revert;
            margin-top: 4px;
            margin-bottom: 4px;
            
        }
        .btn_main{
            @include p;
            width: 100%;
            height: 41px;
            margin-top: 18px;
            &:hover{
                color: $white;
            }
        }
    }
    &_close{
        background: url(/img/svg/close-cross.svg) 50% / cover;
        height: 15px;
        width: 15px;
        margin-top: 0;
        margin-right: 0;
        padding: 0;
        position: absolute;
        right: 15px;
    }
    &.show{
        .drop{
            &_body{
                opacity: 1;
                z-index: 100;
                height: auto;
                transition: height .5s ease;
            }
        }
    }
}
.grecaptcha-badge{
    &{
        display: none;
    }
}