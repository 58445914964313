$black: #000000;
$darkGray: #48494F; 
$yellow: #FED02D; 
$white: #FFFFFF; 
$red: #EB2629;
$lightGray: #B3B3B3;
$lightGreen: #27E394; 
$dirtyWhite: #E8E9EB;
$blue: #0803F9;

@mixin h1{
    font-family: Montserrat, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 112.5% */
    letter-spacing: -1.44px;
    color: $black;
    hyphens: auto;
}
@mixin h2{
    font-family: Montserrat, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    color: $black;
    hyphens: auto;
    @media screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 117%;
    }
}
@mixin h3{
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $black;
    hyphens: auto;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
}
@mixin h4{
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: -0.48px;
    text-transform: uppercase;
    color: $black;
    hyphens: auto;
    @media screen and (max-width:991px) {
        font-size: 16px;
    }
}
@mixin p{
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.8px;
    color: $black;
    hyphens: auto;
    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}
@mixin h5{
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: $black;
    hyphens: auto;
}
@mixin h6{
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    hyphens: auto;
    
}
@mixin h7{
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 100% */
    text-transform: uppercase;    
    hyphens: auto;
}
@mixin line-height-18{
    line-height: 18px;
}
@mixin line-height-26{
    line-height: 26px;
}
@mixin bold{
    font-weight: 700;
}
@mixin regular{
    font-weight: 400;
}
@mixin w-500{
    font-weight: 500;
}
@mixin jcfe{
    justify-content: flex-end;
}
@mixin jcc{
    justify-content: center;
}
@mixin jcsa{
    justify-content: space-around;
}
@mixin jcsb{
    justify-content: space-between;
}
@mixin h_list{
    list-style: none;
    padding-left: 0;
    display: flex;
}